import { createTheme }   from '@material-ui/core/styles';
import ArboriaWoff       from '../assets/fonts/arboria-medium.woff';
import ArboriaWoff2      from '../assets/fonts/arboria-medium.woff2';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const arboria: CSSProperties = {
  fontFamily: 'Arboria',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local("Arboria"),
    url(${ArboriaWoff2}) format("woff2"),
    url(${ArboriaWoff}) format("woff")
  `
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
      light: '#f5f5f5'
    },
    secondary: { main: '#dc1839' }
  },
  typography: {
    fontFamily: 'Arboria, sans-serif',
    h1: {
      fontWeight: 500,
      fontSize: '2.25rem'
    },
    h2: {
      fontWeight: 500,
      fontSize: '2rem'
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.75rem'
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.5rem'
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.25rem'
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem'
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: 1.4
    },
    subtitle2: { fontSize: '1rem' }
  },
  props: {
    MuiButtonBase: { disableRipple: true, },
    MuiButton: { variant: 'contained', },
    MuiTextField: { variant: 'outlined', },
    MuiSelect: { variant: 'outlined', },
  },
  overrides: {
    MuiCssBaseline: { '@global': { '@font-face': [arboria], } },
    MuiFormLabel: {
      root: {
        display: 'block',
        color: 'inherit',
        lineHeight: 'inherit'
      },
      asterisk: { color: 'red' }
    },
    MuiOutlinedInput: {
      root:      {
        backgroundColor: 'var(--color-input-background)',
        borderRadius:    '6px'
      },
      multiline: { padding: '12px' },
      input:     { padding: '12px' }
    },
    MuiInputLabel: {
      filled: {
        transform: 'translate(12px, 10px)',
        '&.MuiInputLabel-shrink': { display: 'none' } // Shrunk labels are not displaying correctly in text fields
      },
    },
    MuiButton: {
      root: {
        appearance: 'none',
        textTransform: 'none',
        backgroundColor: 'transparent',
        color: 'var(--color-button, var(--color-primary))',
        borderRadius: '4px',
        borderColor: 'var(--color-button, var(--color-primary))',
        transition: 'all 0.3s',
        height: '3rem',
        lineHeight: '3rem',
        minWidth: '160px',
        fontSize: '1.125rem',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        padding: '0 1.5rem',
        verticalAlign: 'baseline',
        '&:hover': { background: 'var(--color-button-hover, var(--color-secondary))', },
      },
      outlined: {
        backgroundColor: 'transparent',
        border: '0.125rem solid',
        '&:hover': {
          backgroundColor: 'var(--color-button-hover, var(--color-secondary))',
          borderColor: 'var(--color-button-hover, var(--color-secondary))',
          color: 'var(--color-button-contrast, var(--color-primary-contrast))',
        },
      },
      outlinedPrimary: {
        backgroundColor: 'transparent',
        border: '0.125rem solid',
        '&:hover': {
          backgroundColor: 'var(--color-button-hover, var(--color-secondary))',
          borderColor: 'var(--color-button-hover, var(--color-secondary))',
          color: 'var(--color-button-contrast, var(--color-primary-contrast))',
        },
      },
      outlinedSecondary: {
        backgroundColor: 'transparent',
        border: '0.125rem solid',
        '&:hover': {
          backgroundColor: 'var(--color-button-hover, var(--color-secondary))',
          borderColor: 'var(--color-button-hover, var(--color-secondary))',
          color: 'var(--color-button-contrast, var(--color-primary-contrast))',
        },
      },
      contained: {
        backgroundColor: 'var(--color-button, var(--color-primary))',
        color: 'var(--color-button-contrast, var(--color-primary-contrast))',
        border: 0,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'var(--color-button-hover, var(--color-secondary))',
        },
      },
      containedPrimary: { '&:hover': { backgroundColor: 'var(--color-button-hover, var(--color-secondary))', }, },
      sizeSmall: {
        height: '2rem',
        lineHeight: '1.75rem',
        fontSize: '0.875rem',
      }
    }
  }
});

export default theme;
